import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { UserRole } from "../../models/";
import { ReactNode } from "react";

export enum NotificationType {
  Alert,
  Warning,
  Info,
  PrerequisiteWarning,
}

export enum NotificationTopic {
  Billing,
  Other,
}

export interface INotificationBarProps {
  message: ReactNode;
  type: NotificationType;
  topic: NotificationTopic;
  userRole?: UserRole;
  buttonText?: ReactNode;
  hidden?: boolean;
  to?: string;
  handleClick?: () => void;
  overrideIcon?: IconDefinition;
  onClose?: () => void;
  cutoffText?: boolean;
}
