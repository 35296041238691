import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Usage, UsageBar, UsageType } from "../../../components/usage";
import {
  FeatureId,
  IAggregatePeriod,
  IAggregatePeriodList,
  IBillingUsage,
  ISubscriptionInfo,
  ISubscriptionLimits,
} from "../../../models";
import { ListGroup } from "react-bootstrap";

interface IBillingUsageBars {
  aggregateInfo?: IAggregatePeriodList;
  billingUsageInfo: IBillingUsage;
  subscriptionInfo: ISubscriptionInfo;
}

export const BillingUsageBars = ({
  aggregateInfo,
  billingUsageInfo,
  subscriptionInfo,
}: IBillingUsageBars) => {
  const limits = subscriptionInfo.limits ?? ({} as ISubscriptionLimits);

  const planContainsAIGenerator =
    limits.availableFeatureIds?.includes(FeatureId.AIGenerator) ?? false;

  const showAdditionalLimits =
    limits.dataSourceLimit > 0 ||
    limits.connectionLimit > 0 ||
    limits.userLimit > 0 ||
    limits.aiCreditLimit > 0;

  function renderUsageBar(
    testId: string,
    title: string,
    count: number,
    limit: number,
    usageType: UsageType,
  ) {
    return (
      <div className="col-sm-6">
        <ListGroup variant="flush" data-testid={testId}>
          <ListGroup.Item className="no-margin mb-3 pt-0">
            <h5 className="mb-2 fw-bold">{title}</h5>
            <UsageBar
              aggregate={{ usage: count, usageLimit: limit }}
              usageType={usageType}
              billingStatus={subscriptionInfo.billingStatus}
              bottomDivider={false}
            />
          </ListGroup.Item>
        </ListGroup>
      </div>
    );
  }

  return (
    <Row>
      <div className="col-12">
        <Card>
          <CardBody>
            {limits.rowLimit && aggregateInfo?.aggregates ? (
              <div data-testid="usage-rows">
                <Usage
                  aggregates={aggregateInfo?.aggregates.map(
                    (row: IAggregatePeriod, index: number) => {
                      return {
                        usage: row.rowsReturned,
                        usageLimit:
                          index === 0 ? limits.rowLimit : row.rowLimit,
                        periodEnd: row.periodEnd,
                        periodStart: row.periodStart,
                      };
                    },
                  )}
                  usageType={UsageType["row"]}
                  billingStatus={subscriptionInfo.billingStatus}
                  bottomDivider={showAdditionalLimits}
                />
              </div>
            ) : null}
            <Row>
              {showAdditionalLimits ? (
                <Row className="mb-2">
                  <Col>
                    <h4>Additional Usage</h4>
                  </Col>
                </Row>
              ) : null}
              {limits.dataSourceLimit > 0
                ? renderUsageBar(
                    "usage-datasources",
                    "Data Sources",
                    billingUsageInfo?.dataSourcesCount,
                    limits.dataSourceLimit,
                    UsageType["data source"],
                  )
                : null}
              {limits.connectionLimit > 0
                ? renderUsageBar(
                    "usage-connections",
                    "Connections",
                    billingUsageInfo?.connectionsCount,
                    limits.connectionLimit,
                    UsageType["connection"],
                  )
                : null}
              {limits.userLimit > 0
                ? renderUsageBar(
                    "usage-userseats",
                    "Users",
                    billingUsageInfo?.usersCount,
                    limits.userLimit,
                    UsageType["user seat"],
                  )
                : null}
              {limits.aiCreditLimit > 0 && planContainsAIGenerator
                ? renderUsageBar(
                    "usage-aicredits",
                    "AI Credits",
                    billingUsageInfo?.aiCreditsCount,
                    limits.aiCreditLimit,
                    UsageType["credit"],
                  )
                : null}
            </Row>
          </CardBody>
        </Card>
      </div>
    </Row>
  );
};
