"use client";
import React, {
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { IBillingUsage, ISubscriptionInfo, UserRole } from "../models";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import Loader from "../components/Loader";

import store from "../redux/store/index";
import { RequestType } from "../components/withAPI";
import {
  INotificationBarProps,
  NotificationBarManager,
} from "../components/notification";
import "../assets/scss/_app.scss";
import { SUPPORTED_BROWSERS } from "../components/notification/NotificationBarConstants";
import { isMacSafariWebView } from "../components/notification/NotificationBarUtil";
import { browserName } from "react-device-detect";
import {
  addOverageNotification,
  addTrialRowNotification,
  addBillingIssueNotification,
  addBrowserNotification,
  addTrialNotification,
  addODataNotification,
  addCacheConnectionNotification,
} from "../components/notification/NotificationBarManager";
import { ModalContext } from "../routes/ModalContext";
import { useSessionState } from "../hooks/useSessionState";
import { tosLastUpdated } from "../utility/TermsOfServiceLastUpdated";
import { Button } from "reactstrap";
import { IModalProps } from "../components/CDataModal";
import { useAPI } from "../components/useAPI";
import { logoutUser } from "../routes/AuthorizeContext";
import { RootState } from "../redux/reducers";
import { useFeatureFlags } from "../hooks/useFeatureFlags";
import { ErrorBoundary } from "react-error-boundary";
import {
  GenericErrorPage,
  logBoundaryErrorToDatadog,
} from "../pages/auth/GenericErrorPage";

export interface IModalFunctions {
  showErrorModal: Function;
  showTimeoutModal: Function;
  setModal: Function;
  toggleModal: () => void;
  showUnsupportedBrowser?: Function;
}

interface IDashboardProps {
  subscription: ISubscriptionInfo;
  usage: IBillingUsage;
}

const Dashboard: React.FC<IDashboardProps> = (props) => {
  const api = useAPI();
  const navigate = useNavigate();
  const user = useSelector((s) => s.user);
  const isOemAdmin = user?.role === UserRole.OEMAdmin;
  const [loading, setLoading] = useState<boolean>(true);
  const [notificationBar, setNotificationBar] = useState<
    INotificationBarProps | undefined
  >(undefined);
  const prevProps = useRef<IDashboardProps | null>({} as IDashboardProps);
  const modalContext = useContext(ModalContext);
  const sessionState = useSessionState();
  const datasetsFlag = useFeatureFlags().getFlags(["datasets_client"]);
  const cacheConnection = useSelector(
    (state: RootState) => state.cacheConnection,
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await sessionState.initializeSessionState();
      if (!store.getState().accountInfo!.isSetupFinished) {
        navigate("/initial-setup#select-initial-connection");
      }

      displayInitialUnsupportedBrowser();
      displayNotificationBar();
      observeUrlChangeForNotifications();
      displayTermsOfServiceModal();

      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const hasConnectionsOverLimit =
      props.usage?.connectionsOverLimit !== undefined;
    const hasUsersOverLimit = props.usage?.usersOverLimit !== undefined;
    const hasPlanId = props.subscription?.plan?.id !== undefined;

    if (
      (hasConnectionsOverLimit && hasUsersOverLimit && hasPlanId) || // Condition for initial mount
      (hasConnectionsOverLimit &&
        hasUsersOverLimit &&
        hasPlanId &&
        (props.usage.connectionsOverLimit !==
          prevProps.current!.usage.connectionsOverLimit ||
          props.usage.usersOverLimit !==
            prevProps.current!.usage.usersOverLimit ||
          props.subscription.plan!.id !==
            prevProps.current!.subscription.plan!.id)) // Condition for updates
    ) {
      displayNotificationBar();
    }

    // Update previous props after rendering
    prevProps.current = props;
  }, [props]);

  const observeUrlChangeForNotifications = () => {
    let previousUrl = document.location.href;
    const observer = new MutationObserver(() => {
      if (
        previousUrl !== document.location.href &&
        (previousUrl.includes("settings") ||
          document.location.href.includes("settings"))
      ) {
        previousUrl = document.location.href;
        displayNotificationBar();
      }
    });
    observer.observe(document, { childList: true, subtree: true });
  };

  const displayInitialUnsupportedBrowser = () => {
    if (
      !SUPPORTED_BROWSERS.includes(browserName) &&
      !isMacSafariWebView(navigator.userAgent)
    ) {
      modalContext.showUnsupportedBrowser();
    }
  };

  const displayNotificationBar = () => {
    const user = store.getState().user;
    const usage = store.getState().usage;
    const subscription = store.getState().subscription;
    const datasetsFlagEnabled = datasetsFlag.datasets_client.enabled;

    const modifyingCacheConnection = addCacheConnectionNotification();
    if (cacheConnection?.id && modifyingCacheConnection) {
      setNotificationBar(modifyingCacheConnection);
      return;
    }

    const billingIssue = addBillingIssueNotification(user!, subscription!);
    if (billingIssue) {
      setNotificationBar(billingIssue);
      return;
    }

    const userConnectionOverage = addOverageNotification(user!, usage!);
    if (userConnectionOverage) {
      setNotificationBar(userConnectionOverage);
      return;
    }

    const trialRowsExceeded = addTrialRowNotification(
      user!,
      usage!,
      subscription!,
    );
    if (trialRowsExceeded) {
      setNotificationBar(trialRowsExceeded);
      return;
    }

    const trialTimeRemaining = addTrialNotification(user!, subscription!);
    if (trialTimeRemaining) {
      setNotificationBar(trialTimeRemaining);
      return;
    }

    const unsupportedBrowser = addBrowserNotification(
      modalContext.showUnsupportedBrowser,
    );
    if (unsupportedBrowser) {
      setNotificationBar(unsupportedBrowser);
      return;
    }

    const deprecatedOData = addODataNotification(datasetsFlagEnabled);
    if (deprecatedOData) {
      setNotificationBar(deprecatedOData);
      return;
    }

    setNotificationBar(undefined);
  };

  function displayTermsOfServiceModal() {
    if (store.getState().accountInfo!.tosVersion! < tosLastUpdated) {
      const modal = {
        title: "Accept New Terms and Conditions",
        body: (
          <div>
            Our{" "}
            <a
              href="https://cloud.cdata.com/docs/terms-of-service.html"
              className="text-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{" "}
            have been updated since the last time you logged on. You will need
            to accept the new terms to continue.
          </div>
        ),
        primaryButton: (
          <Button
            color="primary"
            onClick={updateTosAcceptedDate}
            data-testid="button-accept-tos"
          >
            I agree
          </Button>
        ),
        secondaryButton: (
          <Button
            color="secondary"
            tag="a"
            onClick={() => logoutUser()}
            className="logout-button"
            data-testid="button-logout"
          >
            Return to Login
          </Button>
        ),
        displayToggleCloseButton: false,
        modalSize: "md",
        displayed: true,
      } as IModalProps;
      modalContext.setModal(modal);
    }
  }

  const updateTosAcceptedDate = async () => {
    const { status } = await api.callAPI(
      RequestType.Put,
      `/account/tos/${tosLastUpdated}`,
      "Failed to update the Terms of Service acceptance date",
    );
    if (status === 200) {
      modalContext.toggleModal();
    }
  };

  const renderDashboard = () => {
    return (
      <>
        <Navbar />
        <Wrapper>
          {/* OEM admins currently do not get a sidebar, this will change if they ever have more than 1 page they can see. */}
          {!isOemAdmin && <Sidebar />}
          <Main className="">
            <ErrorBoundary
              fallbackRender={(props) => (
                <GenericErrorPage
                  error={props.error}
                  isOutsideDashboard={false}
                  resetErrorBoundary={props.resetErrorBoundary}
                />
              )}
              onError={logBoundaryErrorToDatadog}
            >
              <NotificationBarManager notification={notificationBar!} />
              <Content>
                <Suspense fallback={<Loader />}>
                  <Outlet />
                </Suspense>
              </Content>
              <Footer />
            </ErrorBoundary>
          </Main>
        </Wrapper>
      </>
    );
  };

  const contents = loading ? (
    <div className="text-center mt-4">
      <Loader />
    </div>
  ) : (
    renderDashboard()
  );

  return (
    <>
      <div className="p-0 layouts-dashboard">{contents}</div>
    </>
  );
};

export default connect((state) => {
  return {
    subscription: state.subscription,
    usage: state.usage,
  };
})(Dashboard);
