// @ts-strict-ignore
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import UserProfileCard from "./UserProfileCard";
import Loader from "../../../components/Loader";
import { RequestType } from "../../../components/withAPI";
import { IUser, UserRole } from "../../../models";
import EmailChangeCard from "./EmailChangeCard";
import SupportAccessCard from "./SupportAccessCard";
import { useFeatureFlags } from "../../../hooks/useFeatureFlags";
import { useSelector } from "react-redux";
import { useAPI } from "../../../components/useAPI";
import { getIsSupportImpersonationActive } from "../../../services/userImpersonation";

const UserProfileTab = () => {
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSSO, setIsSSO] = useState<boolean>(false);
  const flags = useFeatureFlags().getFlags(["impersonation"]);
  const api = useAPI();
  useEffect(() => {
    getCurrentUserInfo();
    getIsSSO();
  }, []);
  const user = useSelector((u) => u.user);
  const isOemAdmin = user.role === UserRole.OEMAdmin;
  const isOemSub = user.role === UserRole.ServiceUser;
  const isSupportImpersonationActive = getIsSupportImpersonationActive();

  async function getCurrentUserInfo() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/users/self",
      "Failed to get current user info due to the following error.",
      null,
      undefined,
      undefined,
      isOemSub,
    );
    if (status === 200) {
      await setUserInfo(payload);
    }
    setLoading(false);
  }

  async function getIsSSO() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/account/sso",
      "Failed to get account SSO status.",
    );
    if (status === 200) {
      setIsSSO(payload);
    }
  }

  function renderUserProfileCard() {
    const initialFormValues = {
      LoginEmail: userInfo?.pendingEmail ?? userInfo!.email!,
    };

    return (
      <Row>
        <Col>
          <UserProfileCard
            isSupportImpersonationActive={isSupportImpersonationActive}
          />
          <EmailChangeCard
            initialValues={initialFormValues}
            userInfo={userInfo!}
            setUserInfo={setUserInfo}
            isSSO={isSSO}
            isSupportImpersonationActive={isSupportImpersonationActive}
          />
          {flags.impersonation.enabled && !isOemAdmin && (
            <SupportAccessCard
              userInfo={userInfo!}
              setUserInfo={setUserInfo}
              isSupportImpersonationActive={isSupportImpersonationActive}
            />
          )}
        </Col>
      </Row>
    );
  }

  return (
    <Container fluid className="p-0">
      {loading ? <Loader /> : renderUserProfileCard()}
    </Container>
  );
};

export default UserProfileTab;
