// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Flyout
export const FLYOUT_VISIBILITY_TOGGLE = "FLYOUT_VISIBILITY_TOGGLE";
export const FLYOUT_VISIBILITY_SHOW = "FLYOUT_VISIBILITY_SHOW";
export const FLYOUT_VISIBILITY_HIDE = "FLYOUT_VISIBILITY_HIDE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Actions
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const ADD_CATALOG = "ADD_CATALOG";
export const ADD_DRIVERS_LIST = "ADD_DRIVERS_LIST";
export const ADD_USAGE = "ADD_USAGE";
export const ADD_USER = "ADD_USER";
export const ADD_ACCOUNT_INFO = "ADD_ACCOUNT_INFO";
export const UPDATE_DRAG_DATA = "UPDATE_DRAG_DATA";
export const UPDATE_DATA_EXPLORER_TABS = "UPDATE_DATA_EXPLORER_TABS";
export const UPDATE_DATA_EXPLORER_SELECTED_TAB =
  "UPDATE_DATA_EXPLORER_SELECTED_TAB";
export const UPDATE_DATA_EXPLORER_TAB_TITLE_COUNTER =
  "UPDATE_DATA_EXPLORER_TAB_TITLE_COUNTER";
export const UPDATE_DATA_EXPLORER_QUERY_TAB_TITLE_COUNTER =
  "UPDATE_DATA_EXPLORER_QUERY_TAB_TITLE_COUNTER";

// Cache Connection
export const ADD_CONNECTION = "ADD_CONNECTION";
