import { AnyAction } from "redux";
import * as types from "../constants";
import { IQueryTab } from "../../pages/dataExplorer/useQueryTabs";
import { createDeepCopy } from "../../utility/CreateDeepCopy";

export interface IQueryTabInfo {
  tabs: IQueryTab[];
  selectedTab: number;
  queryTabTitleCounter: number;
  queryBuilderTabTitleCounter: number;
}

const initialState: IQueryTabInfo = {
  tabs: [],
  selectedTab: 0,
  queryTabTitleCounter: 1,
  queryBuilderTabTitleCounter: 1,
};

export default function reducer(
  state = initialState,
  action: AnyAction,
): IQueryTabInfo {
  switch (action.type) {
    case types.UPDATE_DATA_EXPLORER_TABS: {
      const newTabs = createDeepCopy(action.payload as IQueryTab[]);

      // Set unsaved changes to false, so no previously running tabs show loading spinners
      newTabs.forEach((tab) => (tab.isLoading = false));

      return {
        ...state,
        tabs: createDeepCopy(newTabs),
      };
    }
    case types.UPDATE_DATA_EXPLORER_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case types.UPDATE_DATA_EXPLORER_TAB_TITLE_COUNTER:
      return {
        ...state,
        queryTabTitleCounter: action.payload,
      };
    case types.UPDATE_DATA_EXPLORER_QUERY_TAB_TITLE_COUNTER:
      return {
        ...state,
        queryBuilderTabTitleCounter: action.payload,
      };
    default:
      return state;
  }
}
