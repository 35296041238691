// @ts-strict-ignore
import axiosRetry, { exponentialDelay } from "axios-retry";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { BackendType, IAPIResponse, RequestType } from "./withAPI";
import { useContext } from "react";
import { ModalContext } from "../routes/ModalContext";
import { useAuthentication } from "../hooks/useAuthentication";
import {
  getImpersonatingUserId,
  getIsSupportImpersonationActive,
  isImpersonating,
} from "../services/userImpersonation";
import { prependApiPrefix } from "../api/prependApiPrefix";

axiosRetry(axios, { retries: 5 });

// Exponential back-off retry delay between requests
axiosRetry(axios, { retryDelay: exponentialDelay });
export function useAPI() {
  const modalFunctions = useContext(ModalContext);

  const auth = useAuthentication();

  axiosRetry(axios, { retries: 5 });
  async function callAPI<TReturn = any>(
    requestType: RequestType,
    url: string,
    errorTitle: string,
    payload?: any,
    _endPoint?: BackendType,
    header?: { [key: string]: string },
    disableImpersonation?: boolean,
  ): Promise<IAPIResponse<TReturn>> {
    const isOemUser = window.location.pathname.startsWith("/oem/user/");

    let accessToken: string | null = "";
    try {
      // OEM users have a JWT in session storage that authenticates them for a single page.
      if (isOemUser) {
        accessToken = sessionStorage.getItem("oemJwt");
      } else {
        accessToken = await auth.GetAccessTokenSilently();
      }
    } catch (error) {
      return { status: 400, payload: {} as TReturn, error: error.error };
    }

    const axiosRequestConfigHeaders: HeadersInit = {
      Authorization: `Bearer ${accessToken}`,
      "content-type": "application/json",
    };
    if (!disableImpersonation && isImpersonating()) {
      axiosRequestConfigHeaders["X-CData-Impersonated-User"] =
        getImpersonatingUserId()!;
      if (getIsSupportImpersonationActive()) {
        // This header is used by Cloudflare to verify VPN status of the logged in user.
        axiosRequestConfigHeaders["cdata-impersonation"] = "active";
      }
    }

    Object.assign(axiosRequestConfigHeaders, header);

    const getConfig: AxiosRequestConfig = {
      url: prependApiPrefix(url),
      data: JSON.stringify(payload),
      headers: axiosRequestConfigHeaders,
    };

    if (RequestType.Post === requestType) {
      getConfig.method = "post";
    } else if (RequestType.Delete === requestType) {
      getConfig.method = "delete";
    } else if (RequestType.Get === requestType) {
      getConfig.method = "get";
    } else if (RequestType.Put === requestType) {
      getConfig.method = "put";
    }

    try {
      const response: AxiosResponse<any, any> = (await axios
        .request(getConfig)
        .catch((error: AxiosError) => {
          const err = error.response!.data as any;
          error.response!.data = err.error;

          if (error.response!.status === 401 && !isOemUser) {
            modalFunctions.showTimeout();
          } else if (error.response!.status === 404 && !err?.error) {
            // 404 status, no error provided by backend
            if (errorTitle !== "") {
              modalFunctions.showError(errorTitle, {
                message: "404: Endpoint not found",
              });
            }
          } else {
            //If no error title is found, handle the error in a custom way
            if (errorTitle !== "") {
              modalFunctions.showError(errorTitle, err.error);
            }
          }

          return error.response;
        }))!;
      if (response.status === 200 || response.status === 204) {
        return {
          status: response.status,
          payload: response.data,
          error: "",
          headers: response.headers,
        };
      }
      //else is error
      return {
        status: response.status,
        payload: {} as TReturn,
        headers: response.headers,
        error: response.data,
      };
    } catch (error) {
      return {
        status: 400,
        payload: {} as TReturn,
        error: error.error || error,
      };
    }
  }

  async function downloadAPI(
    url: string,
    errorTitle: string,
    fileName: string,
    extension = ".zip",
    method: "GET" | "POST" = "GET",
    body: any = null,
    handle408Error = true,
  ): Promise<IAPIResponse> {
    let accessToken = "";
    try {
      accessToken = await auth.GetAccessTokenSilently();
    } catch (error) {
      return { status: 400, payload: {}, error: error.error };
    }

    const requestOptions: RequestInit = {
      method: method,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    if (isImpersonating()) {
      (requestOptions.headers as Record<string, string>)[
        "X-CData-Impersonated-User"
      ] = getImpersonatingUserId()!;
      if (getIsSupportImpersonationActive()) {
        // This header is used by Cloudflare to verify VPN status of the logged in user.
        (requestOptions.headers as Record<string, string | undefined>)[
          "cdata-impersonation"
        ] = "active";
      }
    }

    if (method === "POST" && body !== null) {
      requestOptions.body = JSON.stringify(body);
    }

    try {
      return await fetch(prependApiPrefix(url), requestOptions).then(
        async (resp) => {
          if (resp.status === 200) {
            resp.blob().then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.setAttribute("download", fileName + extension);
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            });
            return { status: 200, payload: {} };
          } else {
            const err = await resp.json();
            //If no error is found, let the user handle the error in a custom way
            if (errorTitle === "")
              return { status: resp.status, payload: {}, error: err.error };
            if (resp.status === 401) {
              modalFunctions.showTimeout();
            } else if (resp.status === 408 && handle408Error) {
              modalFunctions.showError(errorTitle, err.error);
            } else if (resp.status !== 408) {
              modalFunctions.showError(errorTitle, err.error);
            }
            return { status: resp.status, payload: {}, error: err.error };
          }
        },
      );
    } catch (error) {
      return { status: 400, payload: {}, error: error.error };
    }
  }

  return { callAPI, downloadAPI };
}
