import { combineReducers } from "redux";

import accountInfo from "./accountInfoReducer";
import cacheConnection from "./cacheConnectionReducer";
import dataExplorerTabs from "./dataExplorerReducer";
import driversList from "./driversListReducers";
import flyout from "./flyoutReducer";
import layout from "./layoutReducer";
import sidebar from "./sidebarReducers";
import subscription from "./subscriptionReducer";
import usage from "./usageReducer";
import user from "./userReducer";

const rootReducer = combineReducers({
  accountInfo,
  cacheConnection,
  dataExplorerTabs,
  driversList,
  flyout,
  layout,
  sidebar,
  subscription,
  usage,
  user,
});

export type RootState = ReturnType<typeof rootReducer>;

// Makes the `useSelector` hook know about our TypeScript types for redux state.
declare module "react-redux" {
  interface DefaultRootState extends RootState {}
}

export default rootReducer;
